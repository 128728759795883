<template>
	<v-layout row>
		<v-flex xs12 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3 xl4 offset-xl4>
			<w-pagination
				:item-name="$tc('projects.project', 2).toLowerCase()"
				:page-number="pagination.page"
				:rows-per-page="pagination.rowsPerPage"
				:total-items="pagination.totalItems"
				@update:page-number="setPaginationPage($event)"
				@update:rows-per-page="setPaginationRowsPerPage($event)"
			/>
		</v-flex>
	</v-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

export default {
	name: 'ProjectsPaginationSelector',
	mixins: [ProjectsManagerModuleGuard],
	computed: {
		pagination: function () {
			return this.$store.state.customers.projects.pagination
		}
	},
	methods: {
		setPaginationPage: function (pageNumber) {
			this.$store.dispatch('customers/projects/setPagination', { page: pageNumber })
		},
		setPaginationRowsPerPage: function (rowsPerPage) {
			this.$store.dispatch('customers/projects/setPagination', { rowsPerPage })
		}
	}
}
</script>

<style scoped>
div {
	align-items: center;
	display: flex;
	flex-direction: row;
}
</style>
